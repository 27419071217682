import { LoadingButton } from "@mui/lab";
import { Button, Dialog, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from 'Global';
import ic_money from 'icons/ic_money.svg';

export default function AddCreditCard(props: any) {
  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    open,
    setOpen,
    store_id,
  } = props;

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar } = useSnackbar();
  /*============================================= */

  const defaultValues: any = {
    name_on_card: "",
    card_number: "",
    card_expiration: "",
    cvv: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [errors, setErrors] = useState({
    card_expiration: false,
  });
  const [loading, setLoading] = useState(false);

  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;

    let formattedValue = value;
    if (name === 'card_number') {
      formattedValue = formatCardNumber(value);
    } else if (name === 'card_expiration') {
      formattedValue = formatExpirationDate(value);
      validateExpirationDate(formattedValue);
    }

    setFormValues({
      ...formValues,
      [name]: formattedValue,
    });
  };

  /*=======================================
  FORMAT FUNCTIONS
  =======================================*/
  const formatCardNumber = (value: string) => {
    return value
      .replace(/\D/g, '') // Eliminar cualquier carácter que no sea un dígito
      .replace(/(.{4})/g, '$1 ') // Añadir un espacio después de cada 4 dígitos
      .trim() // Eliminar cualquier espacio final extra
      .substring(0, 19); // Limitar el total de caracteres a 19 (16 dígitos + 3 espacios)
  };

  const formatExpirationDate = (value: string) => {
    let cleanedValue = value.replace(/\D/g, '');

    if (cleanedValue.length > 2) {
      cleanedValue = cleanedValue.substring(0, 2) + '/' + cleanedValue.substring(2);
    }

    return cleanedValue.substring(0, 5);
  };

  /*=======================================
  VALIDATION FUNCTIONS
  =======================================*/
  const validateExpirationDate = (value: string) => {
    const [month, year] = value.split('/').map(Number);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Los meses son 0-indexados
    const currentYear = currentDate.getFullYear() % 100; // Obtener los últimos dos dígitos del año

    let isValid = true;

    // Validar que el mes no sea superior a 12
    if (month > 12 || month < 1) {
      isValid = false;
    }

    // Validar que la fecha sea superior a la presente
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      isValid = false;
    }

    setErrors({ ...errors, card_expiration: !isValid });
  };

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = () => {
    if (!formValues.name_on_card || formValues.name_on_card.length < 2) {
      enqueueSnackbar('Please enter a valid name', { variant: 'error' });
      return;
    }

    if (!formValues.card_number || formValues.card_number.length < 19) {
      enqueueSnackbar('Please enter a valid card number', { variant: 'error' });
      return;
    }

    if (errors.card_expiration) {
      enqueueSnackbar('Please enter a valid expiration date', { variant: 'error' });
      return;
    }

    if (!formValues.cvv || formValues.cvv.length < 3) {
      enqueueSnackbar('Please enter a valid CVV', { variant: 'error' });
      return;
    }

    setLoading(true);

    const body = {
      store_id: store_id,
      name_on_card: formValues.name_on_card,
      card_number: formValues.card_number,
      card_expiration: formValues.card_expiration,
      cvv: formValues.cvv,
    };

    axios.post(ProjectConfig.api_url + "/payments/updateCreditCard", body,
      {
        headers: {
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.status === "success") {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setOpen(false); // close dialog
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        enqueueSnackbar('Error adding credit card', { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { borderRadius: "1.25rem" } }}
    >
      <div className="DialogContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="dialog_container_title">
              <h2 className="dialog_title"><img src={ic_money} /> Add Credit Card</h2>
              <h3 className="dialog_subtitle">Add Credit Card</h3>
            </div>
          </div>

          <div className="dialogFormContainer">
            <div className='input_group'>
              <Table className="input_table">
                <TableBody>
                  <TableRow>
                    <TableCell className="input_label2">Name On Card</TableCell>
                    <TableCell>
                      <TextField
                        id="name_on_card"
                        name="name_on_card"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.name_on_card}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Card Number</TableCell>
                    <TableCell>
                      <TextField
                        id="card_number"
                        name="card_number"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.card_number}
                        onChange={handleInputChange}
                        inputProps={{ maxLength: 19 }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label2">Card Expiration</TableCell>
                    <TableCell>
                      <TextField
                        id="card_expiration"
                        name="card_expiration"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.card_expiration}
                        onChange={handleInputChange}
                        error={errors.card_expiration}
                        helperText={errors.card_expiration ? "Invalid date MM/AA" : ""}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="input_label2">CVV</TableCell>
                    <TableCell>
                      <TextField
                        id="cvv"
                        name="cvv"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.cvv}
                        onChange={handleInputChange}
                        inputProps={{ maxLength: 4 }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <div className="dialogButtons">
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          className="button_1"
          loading={loading}
        >
          Add Card
        </LoadingButton>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="secondary"
          className="button_secondary"
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}