import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton} from '@mui/material';
import logo from '../../assets/img/logo.svg';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SignUp from './SignUp';
import { useSearchParams } from 'react-router-dom';
import FormLogin from './FormLogin';
import FormCredentialsSent from './FormCredentialsSent';
import FormChangePass from './FormChangePass';
import FormForgotPass from './FormForgotPass';

export default withSnackbar(
  function SignIn(props: any) {
    
    const { setLoading } = props;
    
    useEffect (() => {
      setLoading(false);
    }, []);

    /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    /*============================================= */

    const [loadingButton, setLoadingButton] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [state, setState] = useState('login');
    const [userEmail, setUserEmail] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();


  useEffect (() => {
    const emailVar = localStorage.getItem('email')? localStorage.getItem('email') : '';
    setLoading(false);
    setEmail(emailVar || searchParams.get('email') || '');
    setCode(searchParams.get('code') || '');
  }, []);




  const loginDataDefault = {
    email: email,
    password: '',
  }

  const [loginData, setLoginData] = useState(loginDataDefault);

  const [page, setPage] = useState("login");

  const sign_in = () => {
      axios
        .get(ProjectConfig.api_url+"/users/auth", {
          params: {
            email: loginData.email,
            password: loginData.password,
          },
        })
        .then((response) => {
          console.log(response.data);
          if(response.data.status === "success"){
            
            enqueueSnackbar(response.data.message, { variant: 'success' });
            const user_info = response.data.user_info;
            console.log(user_info);

            localStorage.setItem('ltkn', user_info.ltkn);
            localStorage.setItem('user_name', user_info.user_name);
            localStorage.setItem('role_id', user_info.role_id);
            
            if(user_info.role_id === 1){
              if(user_info.store_id){
                localStorage.setItem('store_id', user_info.store_id);
                window.location.reload();
              } else {
                window.location.href = '/webflow?new_store';
              }
            }
            else{
              window.location.reload();
            }


            
          }
          else
          {
            enqueueSnackbar(response.data.message, { variant: 'error' });
            setLoadingButton(false);
          }
          
         
        })
        .catch((err) => {
          console.log(err.data);
        });

  }  

  return (
  <>
    <div className="section-gradient_component">
        <div className="section-gradient_hero-1"></div>
        <div className="section-gradient_hero-2"></div>
        <div className="section-gradient_hero-3"></div>
        <div className="section-gradient_hero-5"></div>
    </div>

    <div id='structure'>
      <div id="main-container">
        <header data-pc="nav-component" className="nav_padding">
            <div
            data-w-id="68dd9a17-0234-0921-12f0-3adaa0a22869"
            className="nav-cart_demo-background"
            ></div>
            <div nav-scroll="true" className="nav_container">
            <div className="nav_container-background"></div>
            <a href="/" className="nav_logo-link w-nav-brand" aria-label="home">
                <div className="nav_logo w-embed">
                <img src={logo} alt="" className="nav_logo-image" />
                </div>
            </a>
            </div>
        </header>
            

        <div id="main-content" className="alt-main-content">
          {state === "login" && (
            <FormLogin
              setLoading={setLoading}
              setState={setState}
              loginData={loginData}
              setEmail={setEmail}
              setLoginData={setLoginData}
              loadingButton={loadingButton}
              setLoadingButton={setLoadingButton}
              setOpenRegister={setOpenRegister}
              sign_in={sign_in}
            />
          )}
          {state === "changePass" && (
            <FormChangePass
              loadingButton={loadingButton}
              setLoading={setLoading}
              setState={setState}
              email={email}
              code={code}
            />
          )}
          {state === "forgotPass" && (
            <FormForgotPass
              loadingButton={loadingButton}
              setLoading={setLoading}
              setState={setState}
              email={email}
              setEmail={setEmail}
            />
          )}
          {state === "credentialsSent" && (
            <FormCredentialsSent
              logo={logo}
              setState={setState}
              email={email}
              code={code}
              setCode={setCode}
              setLoginData={setLoginData}
              loginDataDefault={loginDataDefault}
            />
          )}

          {state === "signup" && (
            <SignUp
              openRegister={openRegister}
              setOpenRegister={setOpenRegister}
              loginData={loginData}
              setLoginData={setLoginData}
              sign_in={sign_in}
              setLoading={setLoading}
            />
          )}
        </div>
      </div>
    </div>
  </>
  );
}
);