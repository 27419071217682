import axios from 'axios';
import { useEffect, useState } from 'react';
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Button, Chip, CircularProgress, Dialog, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ProjectConfig } from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import 'assets/css/main.css'; 

import PlanEditor from './PlanEditor';
import ListPagination from 'elements/ListPagination';

export default function PlanList(props: any) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = props;

  useEffect(() => {
    setLoading(false);
  }, []);

  const [list, setList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    total_pages: 1,
    total_items: 0,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const category = queryParams.get('category');
  const [refreshList, setRefreshList] = useState(0);

  const [openEditor, setOpenEditor] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page]);

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = () => {
    setLoading(true);
    axios.get(ProjectConfig.api_url + "/plans/list", {
      params: {
        page: page,
        role_id: 1,
      }
    })
    .then((response) => {
        console.log("Plan List ", response.data); 
        setList(response.data.list);
        setPagination(response.data.pagination);
        setLoading(false);
    })
    .catch((err) => {
      console.log(err.response.data);
    })
    .finally(() => {
      setLoading(false);
    });

    localStorage.setItem('page', page.toString());
  };

  /*=========================================================
  DELETE ITEM
  =========================================================*/
  const handleDelete = (plan_id: number) => {
    setActiveItem(plan_id);
    setOpenAlert(true);
  };

  const deleteItem = () => {
    const body = {
      plan_id: activeItem
    };

    axios.post(ProjectConfig.api_url + "/users/delete", body)
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "success") {
          enqueueSnackbar('Usuario eliminado con éxito', { variant: 'success' });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false);
      });
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);

  return (
    <div id='UsersList' className='ModuleContainer'>
      <div className="ModuleWrapper"> 
        <div className="boxContainerHeader">
          <div className="boxContainerTitle">
            <h1>Packages</h1>
          </div>
          <div className="boxContainerActions">
            <Button
              variant="outlined"
              color="info"
              onClick={() => {
                setActiveItem(null);
                setOpenEditor(true);
              }}
              startIcon={<AddIcon />}
              className='btnList'
            >
              Packages
            </Button>
          </div>
        </div>

        <div id='UsersListIn'>
          <div>
            <TableContainer sx={{ width: "100%" }}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Plan</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>ID</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Users</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Monthly price</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Annual price</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}>Status</TableCell>
                    <TableCell sx={{ color: '#a8a8a8', borderBottom: "none" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((item, i) => (
                    <TableRow key={i} hover role="checkbox">
                      <TableCell>{item.plan_name}</TableCell>
                      <TableCell>{item.plan_id}</TableCell>
                      <TableCell>{item.active_subscriptions}</TableCell>
                      <TableCell>${item.price_monthly}</TableCell>
                      <TableCell>${item.price_yearly}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell align="right">
                        <IconButton aria-label="edit" color="primary" size="small" onClick={() => {
                          setActiveItem(item.plan_id);
                          setOpenEditor(true);
                        }}>
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ListPagination
              pagination={pagination}
              page={page}
              onChange={(event: any, value: any) => {
                setPage(value);
              }}
              label="Packages"
              style={{ marginTop: "20px", alignSelf: "center" }}
            />
          </div>
        </div>
      </div>

      <AlertDialog
        open={openAlert}
        setOpen={setOpenAlert}
        subtitle="Are you sure you want to delete this item?"
        send={deleteItem}
      />

      <PlanEditor
        setLoading={setLoading}
        openEditor={openEditor}
        setOpenEditor={setOpenEditor}
        plan_id={activeItem}
        loadList={loadList}
      />
    </div>
  );
};
