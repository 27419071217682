import { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, DialogContentText, TextField } from '@mui/material';
import { GoAlert } from "react-icons/go";


export default function AlertDialog(props: any) {

    const {
        open, 
        setOpen, 
        title = 'Confirm',
        send, 
        subtitle,
        input_confirm_required = false,
        input_confirm_required_text = '',
        input_confirm_required_label = '',
        button_color = 'primary',
        accept_text = 'Confirm',
        cancel_text = 'Cancel'
    } = props;

    const [confirmationInput, setConfirmationInput] = useState('');
    const [error, setError] = useState(false);

    const handleConfirm = () => {
        if (input_confirm_required) {
            if (confirmationInput !== input_confirm_required_text) {
                setError(true);
                return;
            }
        }
        setOpen(false);
        send();
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ sx: { borderRadius: "1.25rem"} }}
        >

            <div className="DialogContainer">
                <div className="ModuleWrapper">
                    <div className="boxContainerHeader">
                        <div className="dialog_container_title">
                            <h2 className="dialog_title"><GoAlert /> {title}</h2>
                            
                            <h3 className="dialog_subtitle">
                                {subtitle ? subtitle : 'Are you sure you want to proceed?'}
                            </h3>
                        </div>
                    </div>
                
                    <div className="dialogFormContainer">
                        {input_confirm_required && (
                            <TextField
                                label={input_confirm_required_label || `Type "${input_confirm_required_text}" to confirm`}
                                value={confirmationInput}
                                onChange={(e) => {
                                    setConfirmationInput(e.target.value);
                                    setError(false);
                                }}
                                sx={{ minWidth: '400px' }}
                                error={error}
                                helperText={error ? 'Input does not match the required text.' : ''}
                                fullWidth
                            />
                        )}
                    </div>
                    
                    <div className="dialogButtons">
                        <Button
                            variant="contained"
                            color={button_color}
                            onClick={handleConfirm}
                        >
                            {accept_text}
                        </Button>

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setOpen(false)}
                        >
                            {cancel_text}
                        </Button>
                    </div>
                    
                </div>    
            </div>
        </Dialog>
    );
}
