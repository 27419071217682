import { LoadingButton } from "@mui/lab";
import { Button, Dialog, RadioGroup, FormControlLabel, Radio, Table, TableBody, TableCell, TableRow, TextField, MenuItem } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from 'Global';
import ic_money from 'icons/ic_money.svg';

export default function PlanChanger(props: any) {
  /*=============================================
  VARIABLES FROM PROPS
  ============================================= */
  const {
    open,
    setOpen,
    store_id,
    plan_data:{
      current_plan_type = "",
      current_plan_id = 0,
      current_plan_price = 0,
      current_active_until = ""
    }
  } = props;

  console.log("Plan Data ", props.plan_data);

  /*SNACKBAR FUNCTION FOR NOTIFICATIONS*/
  const { enqueueSnackbar } = useSnackbar();
  /*============================================= */

  const defaultValues: any = {
    plan_id: current_plan_id || 0,
    take_effect: "immediate",
    amount: current_plan_price || "",
    plan_type: current_plan_type || "",
    active_until: current_active_until || "",
    name_on_card: "",
    card_number: "",
    card_expiration: "",
    cvv: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [errors, setErrors] = useState({
    card_expiration: false,
  });
  const [loading, setLoadingLocal] = useState(false);
  const [planList, setPlanList] = useState<any[]>([]);

  useEffect(() => {
    setFormValues({
      ...defaultValues,
      plan_id: current_plan_id || 0,
      amount: current_plan_price || "",
      plan_type: current_plan_type || "",
      active_until: current_active_until || "",
    });
    
  }, [open]);


  const getPlans = () => {
    axios.get(ProjectConfig.api_url + "/plans/list", {
      params: {
        full_list: 1,
        filters: {
          status: 1,
        },
      },
    })
      .then((response) => {
        console.log("Plan List ", response.data);
        setPlanList(response.data.list);
        
      })
      .catch((err) => {
        console.log(err.response.data);
        
      });
  }

  useEffect(() => {
    getPlans();
  }, []);

  /*=======================================
  HANDLE INPUT CHANGE
  =======================================*/
  const handleInputChange = (e: { target: { name: any; value: any; } }) => {
    if (e.target.name === 'plan_type') {
      const selectedPlan = planList.find(plan => plan.plan_name === formValues.plan);
      if (selectedPlan) {
        const updatedAmount = e.target.value === 'Monthly' ? selectedPlan.price_monthly : selectedPlan.price_yearly;
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value,
          amount: updatedAmount,
        });
        return;
      }
    }
    if (e.target.name === 'plan_type') {
      const selectedPlan = planList.find(plan => plan.plan_name === formValues.plan);
      if (selectedPlan) {
        const updatedAmount = e.target.value === 'Monthly' ? selectedPlan.price_monthly : selectedPlan.price_yearly;
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value,
          amount: updatedAmount,
        });
        return;
      }
    }
    const { name, value } = e.target;

    let formattedValue = value;
    if (name === 'card_number') {
      formattedValue = formatCardNumber(value);
    } else if (name === 'card_expiration') {
      formattedValue = formatExpirationDate(value);
      validateExpirationDate(formattedValue);
    }

    setFormValues({
      ...formValues,
      [name]: formattedValue,
    });
  };

  const handlePlanChange = (e: { target: { value: any } }) => {
    const selectedPlan = planList.find(plan => plan.plan_id === parseInt(e.target.value));
    const updatedAmount = formValues.plan_type === 'monthly' ? selectedPlan.price_monthly : selectedPlan.price_yearly;

    setFormValues({
      ...formValues,
      plan_id: selectedPlan.plan_id,
      amount: updatedAmount,
    });
  };

  const handleTakeEffectChange = (e: { target: { value: any } }) => {
    setFormValues({
      ...formValues,
      take_effect: e.target.value,
    });
  };

  /*=======================================
  FORMAT FUNCTIONS
  =======================================*/
  const formatCardNumber = (value: string) => {
    return value
      .replace(/\D/g, '') // Eliminar cualquier caracter que no sea un digito
      .replace(/(.{4})/g, '$1 ') // Anadir un espacio despues de cada 4 digitos
      .trim() // Eliminar cualquier espacio final extra
      .substring(0, 19); // Limitar el total de caracteres a 19 (16 digitos + 3 espacios)
  };

  const formatExpirationDate = (value: string) => {
    let cleanedValue = value.replace(/\D/g, '');

    if (cleanedValue.length > 2) {
      cleanedValue = cleanedValue.substring(0, 2) + '/' + cleanedValue.substring(2);
    }

    return cleanedValue.substring(0, 5);
  };

  /*=======================================
  VALIDATION FUNCTIONS
  =======================================*/
  const validateExpirationDate = (value: string) => {
    const [month, year] = value.split('/').map(Number);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Los meses son 0-indexados
    const currentYear = currentDate.getFullYear() % 100; // Obtener los ultimos dos digitos del ano

    let isValid = true;

    // Validar que el mes no sea superior a 12
    if (month > 12 || month < 1) {
      isValid = false;
    }

    // Validar que la fecha sea superior a la presente
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      isValid = false;
    }

    setErrors({ ...errors, card_expiration: !isValid });
  };

  /*=======================================
  update credit card
  =======================================*/
  const UpdateCreditCard = async () => {
    if (!formValues.name_on_card || formValues.name_on_card.length < 2) {
      enqueueSnackbar('Please enter a valid name', { variant: 'error' });
      return null;
    }
  
    if (!formValues.card_number || formValues.card_number.length < 19) {
      enqueueSnackbar('Please enter a valid card number', { variant: 'error' });
      return null;
    }
  
    if (errors.card_expiration) {
      enqueueSnackbar('Please enter a valid expiration date', { variant: 'error' });
      return null;
    }
  
    if (!formValues.cvv || formValues.cvv.length < 3) {
      enqueueSnackbar('Please enter a valid CVV', { variant: 'error' });
      return null;
    }
  
    const body = {
      store_id: store_id,
      name_on_card: formValues.name_on_card,
      card_number: formValues.card_number,
      card_expiration: formValues.card_expiration,
      cvv: formValues.cvv,
    };
  
    try {
      const response = await axios.post(ProjectConfig.api_url + "/payments/updateCreditCard", body);
      
      if (response.data.status === "success") {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      
      return response.data; // Retorna la respuesta de axios correctamente
  
    } catch (err: any) {
      console.log(err.response?.data || err.message);
      enqueueSnackbar('Error adding credit card', { variant: 'error' });
      return {
        status: "error"
      }
    }
  };

  const updateStorePlan = async () => {

    const body = {
      store_id: store_id,
      ...formValues,
    };

    axios.post(ProjectConfig.api_url + "/plans/admin/updateStorePlan", body,
      {
        headers: {
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.status === "success") {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          setOpen(false); // close dialog
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.log(err.response.data);
        enqueueSnackbar('Error adding credit card', { variant: 'error' });
      })
      .finally(() => {
        setLoadingLocal(false);
      });
  }
  

  /*=======================================
  SUBMIT FUNCTION
  =======================================*/
  const handleSubmit = async () => {
    
    const responseCreditCard = await UpdateCreditCard()

    if(responseCreditCard.status == "success"){
      await updateStorePlan()
    }

    
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { borderRadius: "1.25rem" } }}
    >
      <div className="DialogContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="dialog_container_title">
              <h2 className="dialog_title"><img src={ic_money} /> Edit Package</h2>
            </div>
          </div>

          <div className="dialogFormContainer">
            <div className='input_group'>
              <Table className="input_table">
                <TableBody>
                  <TableRow>
                    <TableCell className="input_label2">Select Plan</TableCell>
                    <TableCell>
                      <RadioGroup
                        name="plan_id"
                        value={formValues.plan_id}
                        onChange={handlePlanChange}
                      >
                        {planList.map((plan) => (
                          <FormControlLabel key={plan.plan_id} value={plan.plan_id} control={<Radio />} label={plan.plan_name} />
                        ))}
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Take Effect</TableCell>
                    <TableCell>
                      <RadioGroup
                        name="take_effect"
                        value={formValues.take_effect}
                        onChange={handleTakeEffectChange}
                        row
                      >
                        <FormControlLabel value="immediate" control={<Radio />} label="Immediate" />
                        <FormControlLabel value="next_billing_date" control={<Radio />} label="Next Billing Date" />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Amount</TableCell>
                    <TableCell>
                      <TextField
                        id="amount"
                        name="amount"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.amount}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Billing Cycle</TableCell>
                    <TableCell>
                      <TextField
                        id="plan_type"
                        name="plan_type"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.plan_type}
                        onChange={handleInputChange}
                        select
                        inputProps={{ id: 'plan_type-select' }}
                        children={[
                          <MenuItem key="monthly" value="monthly">Monthly</MenuItem>,
                          <MenuItem key="yearly" value="yearly">Yearly</MenuItem>
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Next Billing Date</TableCell>
                    <TableCell>
                      <TextField
                        id="active_until"
                        name="active_until"
                        type="date"
                        size="small"
                        required
                        fullWidth
                        value={formValues.active_until}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Name On Card</TableCell>
                    <TableCell>
                      <TextField
                        id="name_on_card"
                        name="name_on_card"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.name_on_card}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Credit Card</TableCell>
                    <TableCell>
                      <TextField
                        id="card_number"
                        name="card_number"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.card_number}
                        onChange={handleInputChange}
                        inputProps={{ maxLength: 19 }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">CVV</TableCell>
                    <TableCell>
                      <TextField
                        id="cvv"
                        name="cvv"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.cvv}
                        onChange={handleInputChange}
                        inputProps={{ maxLength: 4 }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Exp</TableCell>
                    <TableCell>
                      <TextField
                        id="card_expiration"
                        name="card_expiration"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.card_expiration}
                        onChange={handleInputChange}
                        error={errors.card_expiration}
                        helperText={errors.card_expiration ? "Invalid date MM/AA" : ""}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <div className="dialogButtons">
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          className="button_1"
          loading={loading}
        >
          Apply changes
        </LoadingButton>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="secondary"
          className="button_secondary"
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}