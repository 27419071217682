import { Button, Dialog, Table, TableBody, TableCell, TableRow, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from 'Global';
import ISwitch from "elements/ISwitch";

export default function UsersEditor(props: any) {
  const {
    setLoading, 
    openEditor, 
    setOpenEditor, 
    user_id,
    loadList,
    company_id = null
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: any = {
    user_id: user_id ? user_id : "null",
    name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    is_active: true,
    role_id: 2, 
    company_id: company_id,
    zone_id: 0,
  };
  
  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    if (user_id) {
      axios.get(ProjectConfig.api_url + "/users/details", {
          headers: {
            "ltkn": localStorage.getItem('ltkn')
          },
          params: {
            user_id: user_id,
          },
        })
        .then((response) => {
          setFormValues(response.data);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    } else {
      setFormValues(defaultValues);
    }
  }, [user_id]);

  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));

    axios.post(ProjectConfig.api_url + "/v2/users/addedit", body, {
        headers: {
          "ltkn": localStorage.getItem("ltkn"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          enqueueSnackbar('Success', { variant: 'success' });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog 
      open={openEditor}
      onClose={() => setOpenEditor(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { borderRadius: "1.25rem" } }}
    >
      <div className="DialogContainer">
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="dialog_container_title">
              <h2 className="dialog_title">Administrator Editor</h2>
              <h3 className="dialog_subtitle">Manage user details</h3>
            </div>
          </div>

          <div className="dialogFormContainer">
            <div className='input_group'>
              <Table className="input_table">
                <TableBody>
                  <TableRow sx={{ "&:hover": { backgroundColor: "transparent" } }}>
                    <TableCell className="input_label2">Name</TableCell>
                    <TableCell>
                      <TextField 
                        id="name"
                        name="name"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.name}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Last Name</TableCell>
                    <TableCell>
                      <TextField 
                        id="last_name"
                        name="last_name"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.last_name}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Email</TableCell>
                    <TableCell>
                      <TextField 
                        id="email"
                        name="email"
                        type="text"
                        size="small"
                        required
                        fullWidth
                        value={formValues.email}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Password</TableCell>
                    <TableCell>
                      <TextField 
                        id="password"
                        name="password"
                        type="password"
                        size="small"
                        autoComplete="new-password"
                        required
                        fullWidth
                        value={formValues.password}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Role</TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                          labelId="role-select-label"
                          id="role-select"
                          value={formValues.role_id}
                          name="role_id"
                          size="small"
                          onChange={handleInputChange}
                        >
                          <MenuItem value={1}>Super Admin</MenuItem>
                          <MenuItem value={2}>Admin</MenuItem>
                          <MenuItem value={3}>Analyst</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="input_label2">Status</TableCell>
                    <TableCell>
                      <ISwitch
                        label="Active"
                        checked={formValues.is_active}
                        name="is_active"
                        onChange={(e: any) => {
                          setFormValues({
                            ...formValues,
                            ["is_active"]: e.target.checked,
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <div className="dialogButtons">
        <Button
          onClick={handleSubmit}
          variant="contained"
          className="button_1"
        >
          Save
        </Button>
        <Button
          onClick={() => setOpenEditor(false)}
          variant="contained"
          color="secondary"
          className="button_secondary"
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
